$nav-desktop-breakpoint: 1200px;
$nav-search-desktop-breakpoint: 920px;
$nav-topnav-logo-desktop-breakpoint: 400px;
$nav-topnav-mobile-expected-height: 3.5rem; // 56px

@mixin nav-mobile-only {
	@media (max-width: $nav-desktop-breakpoint - 1px) {
		@content;
	}
}

@mixin nav-desktop-only {
	@media (min-width: $nav-desktop-breakpoint) {
		@content;
	}
}

@mixin nav-search-mobile-only {
	@media (max-width: $nav-search-desktop-breakpoint - 1px) {
		@content;
	}
}

@mixin nav-search-desktop-only {
	@media (min-width: $nav-search-desktop-breakpoint) {
		@content;
	}
}

@mixin nav-topnav-logo-mobile-only {
	@media (max-width: $nav-topnav-logo-desktop-breakpoint - 1px) {
		@content;
	}
}

@mixin nav-topnav-logo-desktop-only {
	@media (min-width: $nav-topnav-logo-desktop-breakpoint) {
		@content;
	}
}

@mixin nav-link {
	display: block;
	color: #fff;
	text-decoration: none;
	transition:
		background-color 0.2s ease,
		color 0.2s ease;
}
