.badge {
	border-radius: 0.25rem;
	color: inherit;
}

// TODO deprecated
.badge-light-success {
	background-color: rgba(#1abb9c, 0.2);
	color: #1abc9c !important;
}

// TODO deprecated
.badge-light-danger {
	background-color: rgba(#f1566c, 0.2);
	color: #f1566c !important;
}

// TODO deprecated
.badge-light-warning {
	background-color: rgba(#f7b84b, 0.2);
	color: #f7b84b !important;
}
