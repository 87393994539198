.founder-skill-tooltip {
	opacity: 1;

	div {
		animation-duration: 0s !important;
	}

	.tooltip-inner {
		text-align: start;
		display: flex;
		align-items: center;
		background: $leetify-pink;
		color: #fff;
		border-radius: 0;
	}

	.arrow {
		display: none;
	}
}
