@mixin leetify-button-colors($hover-color, $hover-text, $active-color, $active-text, $disabled-color, $disabled-text) {
	&:hover,
	:focus
	.hover,
	.focus {
		background-color: $hover-color !important;
		border-color: $hover-color !important;
		color: $hover-text !important;
	}

	&:not(:disabled):not(.disabled):active,
	&:not(:disabled):not(.disabled).active,
	.show > &.dropdown-toggle {
		background-color: $active-color !important;
		border-color: $active-color !important;
		color: $active-text !important;
	}

	&:disabled,
	.disabled {
		background-color: $disabled-color !important;
		border-color: $disabled-color !important;
		color: $disabled-text !important;
	}
}

.btn {
	&:focus,
	&.focus {
		box-shadow: none;
	}

	// TODO these two hover classes are (at the time of writing) only used for "Overall/CT Side/T Side" selectors -- those should likely be extracted into a reusable component, and these styles moved to that component
	&.hover-bg-t:hover {
		background: $terrorist;
		border: 1px solid $terrorist;
	}

	&.hover-bg-ct:hover {
		background: $counter-terrorist;
		border: 1px solid $counter-terrorist;
	}

	img {
		height: 24px;
	}
}

// TODO maybe kinda deprecated idk
.btn-secondary {
	&:active,
	&:hover {
		background: $surface5;
	}
}

// TODO deprecated i guess
.btn-bright {
	background: #f1f2f6;
	border-color: #f1f2f6;
	color: #27212d;

	&:focus {
		background-color: #dadce7;
		border-color: #d2d5e2;
		box-shadow: 0 0 0 0.15rem rgba(#4e6894, 0.5);
	}
}

.btn-transparent {
	@include leetify-button-colors(
		rgb(#fff, 0.05),
		inherit,
		rgba(#fff, 0.1),
		inherit,
		transparent,
		$text-muted,
	);

	color: inherit;
	font-weight: 600;
	transition: all 0.2s ease-in-out;
}

.btn-sm,
.btn-group-sm > .btn {
	padding: 0.25rem 0.75rem;
}

.btn-primary {
	@include leetify-button-colors(
		#bf3c68,
		#f1f2f6,
		#862b4c,
		#dfe4ea,
		#692440,
		$text-color,
	);
}

.btn-attached-primary {
	@include button-variant(#E5296A, #E5296A);
	@include leetify-button-colors(
		darken(#E5296A, 5%),
		#f1f2f6,
		darken(#E5296A, 10%),
		#dfe4ea,
		darken(#E5296A, 15%),
		$text-color,
	);
}

.btn-outline-primary {
	@include button-variant(transparent, $leetify-pink);
	@include leetify-button-colors(
		#bf3c68,
		#f1f2f6,
		#862b4c,
		$leetify-pink,
		#692440,
		$text-color,
	);

	color: $leetify-pink;
	font-weight: 600;
}

.btn-secondary {
	@include button-variant(#24212b, #24212b);
	@include leetify-button-colors(
		darken(#24212b, 2%),
		#f1f2f6,
		darken(#24212b, 5%),
		#dfe4ea,
		darken(#24212b, 7%),
		$text-color,
	);
}

.btn-outline-secondary {
	@include button-variant($surface1, $surface5, $surface5, #dfe4ea, #862b4c,#dfe4ea);
	@include leetify-button-colors(
		$text-color,
		$surface1,
		$surface5,
		#dfe4ea,
		$surface1,
		$text-color,
	);
}

// TODO deprecated
.btn-base {
	@include button-variant($surface5, $surface5);
	@include leetify-button-colors(
		darken($surface5, 2%),
		#f1f2f6,
		darken($surface5, 5%),
		#dfe4ea,
		darken($surface5, 7%),
		$text-color,
	);
}

// TODO deprecated
.btn-bright {
	@include button-variant(#f1f2f6, #f1f2f6);

	color: #27212d;
	@include leetify-button-colors(
		#dfe4ea,
		#27212d,
		$text-color,
		#27212d,
		$text-color,
		#fff,
	);

	&:focus {
		color: $surface1;
	}
}

.btn-ct {
	background: $counter-terrorist;
}

.btn-t {
	background: $terrorist;
}
