.tooltip-custom {
	width: auto;
	opacity: 1;
	z-index: 3010;

	.tooltip-inner {
		max-width: 400px;
		width: auto;
		background-color: transparent;

		.card-box {
			background: $surface5 !important;
			box-shadow:
				rgba(#000, 0.25) 0px 54px 55px,
				rgba(#000, 0.12) 0px -12px 30px,
				rgba(#000, 0.12) 0px 4px 6px,
				rgba(#000, 0.17) 0px 12px 13px,
				rgba(#000, 0.09) 0px -3px 5px;
		}

		p {
			color: $text-color;
		}
	}

	&.show {
		opacity: 1;
	}

	&.feature-locked {
		top: 30px !important;
	}

	.arrow {
		display: none;
	}
}
