$primary: $leetify-pink;
$secondary: $surface3;

$info: #37cde6; // TODO likely needs new value from nick
$success: #3aa768; // TODO likely needs new value from nick
$warning: #e5a95c; // TODO likely needs new value from nick

$text-muted: #afa9bc; // TODO likely needs new value from nick

$card-cap-bg: $surface5;
$input-disabled-bg: $surface4;
$input-focus-border-color: #f1f2f6; // TODO from old design, may need to be replaced
$nav-tabs-link-active-bg: #414d59; // TODO from old design, may need to be replaced
$nav-tabs-link-active-border-color: #414d59; // TODO from old design, may need to be replaced
$nav-tabs-link-active-color: $text-color;
$nav-tabs-link-hover-border-color: #414d59; // TODO from old design, may need to be replaced
$progress-bg: $surface5;
$table-hover-bg: $surface3;
$table-hover-color: $text-color;

$font-family-sans-serif: $poppins;

$progress-height: 0.75rem;
