@use 'sass:math';

@import 'vars/index';

@import '@mdi/font/css/materialdesignicons.css'; // TODO don't use the webfont, https://dev.materialdesignicons.com/guide/webfont-alternatives
@import '@ng-select/ng-select/scss/default.theme';
@import 'flag-icon-css/css/flag-icon.css';
@import '../leet-font/leet.css';

@import 'bootstrap';
@import 'fontawesome'; // TODO get rid of this

@import 'tooltips/index';
@import 'typography/index';

@import 'alerts';
@import 'allstar-logo-inline';
@import 'anchors';
@import 'backgrounds';
@import 'badges';
@import 'base';
@import 'buttons';
@import 'cards';
@import 'chartjs';
@import 'cursor';
@import 'hrs';
@import 'inputs';
@import 'modals';
@import 'ng-select';
@import 'progress-bars';
@import 'ribbons';
@import 'spinner';
@import 'syne';
@import 'tables';
@import 'tabs';
