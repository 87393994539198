// usually used for traditional tooltips, i.e. those that just consist of a few words in a single line
.default-tooltip {
	opacity: 1;
	z-index: 3000;

	> div {
		animation-duration: 0.15s !important;
	}

	.tooltip-inner {
		font-size: 0.8rem;
		text-align: center;
	}

	.arrow {
		display: none;
	}

	// TODO deprecated
	&.--dark .tooltip-inner {
		background-color: #24212b;
	}
}
