$violet-red-300: #fa699a;
$violet-red-400: #f84982;

$leetify-founder-blue: #4c49f8;
$leetify-founder-blue-high-contrast: #7e7bff; // TODO not confirmed by Nick
$leetify-pink: $violet-red-400;
$leetify-pink-hover: #cf2564;
$leetify-purple: #7547b6;
$leetify-purple-text: #a86aff; // TODO not confirmed by Nick

$text-color: #ced7e0;

$pale-gold: #ffdf9D;
$gold: #b77b45;

$banned-red: #eb4747;
$personal-best-gold: #f4d090;

$allstar-green: #4dbd94;
$discord-blue: #5865f2;
$discord-blue-hover: #2f3de5;
$twitter-blue: #1da1f2;

$neutral-white: #fff;
$system-misc-danger: #f85249;
$system-misc-gold-flat: #e5bb7d;
