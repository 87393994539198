.text-great {
	color: $great !important;
}

.text-good {
	color: $good !important;
}

.text-average {
	color: $average !important;
}

.text-subpar {
	color: $subpar !important;
}

.text-poor {
	color: $poor !important;
}
