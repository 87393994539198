input {
	color: inherit;
}

.form-control {
	border: 2px solid $form-control-border-color;

	&,
	&:focus {
		background: $surface4;
		color: $text-color;
	}

	&:focus {
		border: 2px solid $input-focus-border-color; // TODO from old design, should likely be replaced
		box-shadow: none;
	}

	&:disabled {
		cursor: not-allowed;
	}
}

.StripeElement--focus {
	border: 2px $input-focus-border-color solid; // TODO from old design, should likely be replaced
}

// TODO deprecated
.custom-control-label {
	&::before {
		background: #413d4d;
		border-color: $surface5;
	}
}
