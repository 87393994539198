// based on css-loaders.com/spinners #20, by Temani Afif, https://github.com/Afif13; modified
@keyframes spinner2-1 {
	0% { clip-path: polygon(50% 50%,0 0, 50% 0%, 50% 0%, 50% 0%, 50% 0%, 50% 0%); }
	12.5% { clip-path: polygon(50% 50%,0 0, 50% 0%, 100% 0%, 100% 0%, 100% 0%, 100% 0%); }
	25% { clip-path: polygon(50% 50%,0 0, 50% 0%, 100% 0%, 100% 100%, 100% 100%, 100% 100%); }
	50% { clip-path: polygon(50% 50%,0 0, 50% 0%, 100% 0%, 100% 100%, 50% 100%, 0% 100%); }
	62.5% { clip-path: polygon(50% 50%,100% 0, 100% 0%, 100% 0%, 100% 100%, 50% 100%, 0% 100%); }
	75% { clip-path: polygon(50% 50%,100% 100%, 100% 100%, 100% 100%, 100% 100%, 50% 100%, 0% 100%); }
	100% { clip-path: polygon(50% 50%,50% 100%, 50% 100%, 50% 100%, 50% 100%, 50% 100%, 0% 100%); }
}

@keyframes spinner2-2 {
	0% { transform: rotate(0); }
	49.99% { transform: rotate(135deg); }
	50% { transform: scaleY(-1) rotate(0); }
	100% { transform: scaleY(-1) rotate(-135deg); }
}
