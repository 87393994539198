// TODO deprecated
.ribbon-box {
	display: block;
	position: relative;

	.ribbon {
		position: relative;
		clear: both;

		padding: 0.3125rem 0.75rem;
		margin-bottom: 1rem;

		box-shadow: 0.125rem 0.3125rem 0.625rem rgba($auth-divider, 0.15);
		color: #fff;
		font-size: 0.8125rem;
		font-weight: 600;

		&::before {
			content: ' ';

			position: absolute;
			bottom: -0.625rem;
			left: 0;

			display: block;
			margin-bottom: -0.625rem;

			border-style: solid;
			border-width: 0.625rem;
			z-index: -1;
		}

		&.float-left {
			margin-left: -1.875rem;
			border-radius: 0 0.1875rem 0.1875rem 0;
		}

		&.float-right {
			margin-right: -1.875rem;
			border-radius: 0.1875rem 0 0 0.1875rem;

			&::before {
				right: 0;
			}

			&.shape {
				clip-path: polygon(2% 0, 100% 1%, 100% 100%, 0 100%, 15% 50%);
				padding-left: 1.5625rem;
				margin-right: -1.5rem;
			}
		}

		&.float-center {
			span {
				margin: 0 auto 1.25rem auto;
			}
		}
	}

	.ribbon-content {
		clear: both;
	}

	.ribbon-primary {
		background: $primary;

		&::before {
			border-color: darken($primary, 10%) transparent transparent;
		}
	}
}
