@keyframes tooltip-fade {
	0% {
		opacity: 0;
	}

	100% {
		opacity: 1;
	}
}

.tooltip > div {
	animation-name: tooltip-fade;
	animation-duration: 0.5s;
}
