* {
	text-rendering: optimizeLegibility;
}

body {
	@include scrollbar;

	background: $surface1;
	color: $text-color;
	color-scheme: dark;
	font: 0.95rem/1.5 $poppins;
	overflow: overlay;

	@include nav-search-mobile-only {
		&.--mobile-search-active {
			overflow: hidden;
		}
	}

	@include nav-desktop-only {
		&.--overflow-hidden {
			overflow: hidden; // prevents tooltips near corners sometimes causing two vertical scrollbars + a horizontal scrollbar -- not great though
		}
	}

	@media (max-width: 749px) {
		&.--landing-mobile-nav-active {
			overflow: hidden; // prevents two scroll bars (body and mobile nav) from being active at once
		}
	}
}
