.alert {
	font-weight: bold;
	display: inline-block;
	border-radius: 3px;

	&.danger {
		color: $poor;
		background-color: rgba($poor, 0.1);
	}

	&.success {
		color: $great;
		background-color: rgba($great, 0.15)
	}
}
