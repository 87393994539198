@font-face {
	font-family: 'leet';
	src: url('./leet.eot');
	src: url('./leet.eot#iefix') format('embedded-opentype'),
		url('./leet.ttf') format('truetype'),
		url('./leet.woff') format('woff'),
		url('./leet.svg#leet') format('svg');
	font-weight: normal;
	font-style: normal;
	font-display: block;
}

[class^='leet-'],
[class*=' leet-'] {
	/* use !important to prevent issues with browser extensions that change fonts */
	font-family: 'leet' !important;
	speak: never;
	font-style: normal;
	font-weight: normal;
	font-variant: normal;
	text-transform: none;
	line-height: 1;

	/* Better Font Rendering =========== */
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.leet-clutch-attempt::before {
	content: '\f129';
}

.leet-clutch-kill::before {
	content: '\f130';
}

.leet-aim::before {
	content: '\f101';
}

.leet-compare::before {
	content: '\f102';
}

.leet-dashboard::before {
	content: '\f103';
}

.leet-datasources::before {
	content: '\f104';
}

.leet-leetify::before {
	content: '\f105';
}

.leet-maps::before {
	content: '\f106';
}

.leet-matches::before {
	content: '\f107';
}

.leet-support::before {
	content: '\f108';
}

.leet-utility::before {
	content: '\f109';
}

.leet-esportal-logo::before {
	content: '\f110';
}

.leet-team-finder::before {
	content: '\f111';
}

.leet-club-add::before {
	content: '\f112';
}

.leet-club-delete::before {
	content: '\f113';
}

.leet-club-info::before {
	content: '\f114';
}

.leet-club-details::before {
	content: '\f115';
}

.leet-member-find::before {
	content: '\f116';
}

.leet-member-recent::before {
	content: '\f117';
}

.leet-member-add::before {
	content: '\f118';
}

.leet-member-check::before {
	content: '\f119';
}

.leet-member-remove::before {
	content: '\f120';
}

.leet-great::before {
	content: '\f121';
}

.leet-good::before {
	content: '\f122';
}

.leet-average::before {
	content: '\f123';
}

.leet-subpar::before {
	content: '\f124';
}

.leet-poor::before {
	content: '\f125';
}

.leet-teamplay-sides::before {
	content: '\f126';
}

.leet-teamplay-duels::before {
	content: '\f127';
}

.leet-teamplay-situation::before {
	content: '\f128';
}

.leet-clutch-save::before {
	content: '\f131';
}

.leet-co-op::before {
	content: '\f132';
}

.leet-focus-areas::before {
	content: '\f133';
}

.leet-gamers-club-logo:before {
	content: '\f134';
}
