$surface1: #19171e;
$surface2: #201e25;
$surface3: #25222c;
$surface4: #2a2732;
$surface5: #2f2b38;

$danger: #F85249;
$surface-danger: rgba($danger, 0.10);
$warning: #F7C648;
$surface-warning: rgba($warning, 0.10);
$success: #3aa768;
$surface-success: rgba($success, 0.10);

$text-light-success: #56DE74;

$overlay: rgba($surface1, 0.85);
