.tab {
	padding: 0.5rem 2.1rem;

	background: #24212b;
	border: none;
	color: $text-muted;
	cursor: pointer;
	text-align: center;
	transition:
		background-color 0.2s ease,
		border-bottom 0.2s ease,
		color 0.2s ease;

	&:disabled {
		opacity: 0.65;
		cursor: not-allowed;
	}

	&:not(:disabled) {
		&:hover {
			background: $surface5;
		}

		&.active {
			background: $surface5;
			border-bottom: 2px solid $leetify-pink;
			color: #fff;

			@media (max-width: 768px) {
				background: $leetify-pink;
				border-bottom: 0px;
			}
		}
	}
}
