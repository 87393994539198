@mixin base-scrollbar($width: 14px, $thumb-color: $scrollbar-thumb-color, $thumb-hover-color: $scrollbar-thumb-hover-color) {
	&::-webkit-scrollbar {
		width: $width;
		height: $width;
		background: transparent;
	}

	&::-webkit-scrollbar-corner,
	&::-webkit-scrollbar-track {
		background: transparent;
	}

	&::-webkit-scrollbar-track-piece {
		&,
		&-start,
		&-end {
			background: transparent;
		}
	}

	&::-webkit-scrollbar-thumb {
		min-height: 2.5rem;

		background: $thumb-color;
		background-clip: content-box;
		border: 0.28 * $width solid transparent;
		border-radius: 0.57 * $width;

		&:active,
		&:hover {
			background-color: $thumb-hover-color;
		}
	}

	@-moz-document url-prefix() {
		scrollbar-color: $thumb-color transparent;
	}
}

@mixin scrollbar($thumb-color: $scrollbar-thumb-color, $thumb-hover-color: $scrollbar-thumb-hover-color) {
	@include base-scrollbar(14px, $thumb-color, $thumb-hover-color);
}

@mixin scrollbar-narrow($thumb-color: $scrollbar-thumb-color, $thumb-hover-color: $scrollbar-thumb-hover-color) {
	@include base-scrollbar(7px, $thumb-color, $thumb-hover-color);

	@-moz-document url-prefix() {
		scrollbar-width: thin;
	}
}
