.table {
	width: 100%;
	color: inherit;

	td {
		@include border-bottom;

		border-top: none;
		vertical-align: middle;
	}

	thead th {
		border-bottom: 0px !important;
		vertical-align: bottom;
	}

	th {
		border-top: 0px !important;
		font-weight: 500;
	}
}

.table-responsive {
	@include scrollbar;
}

.sortable-header {
	cursor: pointer;

	span {
		visibility: hidden;
	}

	&.active {
		span {
			visibility: visible;
			margin-left: 2px;
			font-size: 0.6rem;
		}
	}
}
