.ng-dropdown-panel .ng-dropdown-panel-items {
	.ng-option {
		&,
		&.ng-option-marked {
			color: $text-color;
		}

		&.ng-option-selected {
			&,
			&.ng-option-marked {
				color: $leetify-pink;
			}
		}
	}
}

.ng-select {
	border-radius: 4px;
	transition: border 0.2s ease;

	&.--border {
		border: 2px solid $form-control-border-color;
	}

	&.ng-select-opened {
		border-color: $input-focus-border-color;

		> .ng-select-container {
			.ng-arrow {
				border-color: transparent transparent $text-color;

				&:hover {
					border-color: transparent transparent #fff;
				}
			}
		}
	}

	&.ng-select-multiple .ng-select-container .ng-value-container {
		flex-wrap: nowrap !important;

		.ng-value {
			background-color: $surface3;
			color: $text-color;

			.ng-value-icon {
				border-color: $text-color !important;
			}
		}
	}

	&.ng-select-disabled {
		.ng-select-container .ng-value-container .ng-placeholder {
			opacity: 0.25;
		}
	}

	.ng-select-container {
		border: none;

		.ng-value-container {
			color: #fff;

			.ng-placeholder {
				line-height: 1.75;
			}
		}
	}

	.scroll-host {
		@include scrollbar-narrow;
	}

	.ng-dropdown-panel-items {
		.ng-optgroup {
			color: $text-color;
		}
	}

	.ng-clear-wrapper {
		color: $text-color;

		&:hover .ng-clear {
			color: #fff;
		}
	}

	.ng-arrow-wrapper {
		&:hover .ng-arrow {
			border-top-color: #fff;
		}

		.ng-arrow {
			border-color: $text-color transparent transparent;
		}
	}
}
