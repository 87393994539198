.card,
.card-box {
	@include border;

	background: $surface3;
	box-shadow: 0px 2px 6px 0px rgba($surface1, 0.5);
}

// TODO deprecated (probably)
.card-box {
	@extend .card;
	padding: 1.5rem;
	margin-bottom: 2rem;
}
