@mixin border($color: $border-color) {
	border: 1px solid $color;
}

@mixin border-top($color: $border-color) {
	border-top: 1px solid $color;
}

@mixin border-bottom($color: $border-color) {
	border-bottom: 1px solid $color;
}

@mixin border-left($color: $border-color) {
	border-left: 1px solid $color;
}

@mixin border-right($color: $border-color) {
	border-right: 1px solid $color;
}

@mixin border-radius($border-radius: 0.1875rem) {
	border-radius: $border-radius;
}

@mixin border-x($color: $border-color) {
	@include border-left($color);
	@include border-right($color);
}

@mixin border-y($color: $border-color) {
	@include border-bottom($color);
	@include border-top($color);
}
