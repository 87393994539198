.scoreboard-table-header-cell-tooltip {
	opacity: 1 !important;
	z-index: 3000;

	> div {
		animation-duration: 0.15s !important;
	}

	.tooltip-inner {
		max-width: 400px;
		padding: 1rem 1.5rem;

		background: $surface5;
		box-shadow: 0 4px 4px rgba(#000, 0.25);
		text-align: left;
	}

	.arrow {
		display: none;
	}

	h4 {
		font-size: 1.25rem;
	}

	p {
		&:last-child {
			margin-bottom: 0;
		}
	}
}
